.home {
  padding: 20px 5px;
  .home--row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    > * {
      width: 49% !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .home {
    .home--row {
      display: block;
      margin-bottom: 0;
      > * {
        width: 100% !important;
        margin-bottom: 10px !important;
      }
    }
  }
}
