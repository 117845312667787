.address {
  padding-bottom: 10px;
  .address__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    > * {
      width: 49%;
    }
  }
  .address__billing {
    padding-top: 5px;
  }
  .address__title {
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.84);
  }

  .address__checkbox {
    display: flex;
    font-size: 13px;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 800px) {
  .address {
    padding-bottom: 0;
    .homeMobile__filling__content__input {
      margin-bottom: 10px;
    }
    .address__row {
      margin-bottom: 0;
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }
}
